@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mdc-notched-outline__notch{
  border-right: none !important;
}

.book-details-dialog .mat-mdc-dialog-container {
  padding: 0;
}

.book-details-dialog .mat-mdc-dialog-surface {
  background: transparent;
  border-radius: 12px;
  overflow: hidden;
}

.backdrop-blur-sm {
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.5) !important;
}